
















import Vue from "vue";

export default Vue.extend({
  name: "LogIn",
  data(){
    return {
      login: "",
      password: "",
      validation_message: "",
    }
  },
  components: {},
  methods: {
    logIn(){
      if(this.login == "") {
        this.validation_message = "User name field is empty";
        return;
      }

      if(this.password == "") {
        this.validation_message = "Password field is empty";
        return;
      }

      this.$store.dispatch("logIn", {
        login: this.login,
        password: this.password,
      })
    }
  }
});
